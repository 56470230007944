import React from "react"

const PageTitle = ({ title }) => {
  return (
    <div className={`relative mb-10 md:mb-20`}>
      <div
        className={`pt-10 pb-5 md:pt-24 md:pb-20 rounded-bl-3xl md:rounded-bl-[164px] bg-theme-blue-light relative z-10 px-10`}
      >
        <div className={`container`}>
          <h1 className={``}>{title}</h1>
        </div>
      </div>
      <span
        className={`bg-theme-blue block absolute w-16 h-16 z-0 -bottom-6 left-3 md:left-20 rounded-full`}
      />
    </div>
  )
}

export default PageTitle

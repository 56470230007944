import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import PageTitle from "../components/content/PageTitle"

const Terms = () => {
  return (
    <Layout>
      <Seo title="Terms & Conditions - Mitchells & Butlers" description="" />

      <PageTitle title="Terms & Conditions " />

      <div className={`container relative z-10`}>
        <div className={`w-full mx-auto md:w-3/4 lg:w-1/2 pb-10 md:pb-32`}>
          <ul>
            <li>
              Dine With Us discount vouchers are an exclusive Company benefit
              for Mitchells & Butlers' employees, friends and family and
              employees of approved third-party suppliers.
            </li>

            <li>
              In order to register for a third-party supplier discount voucher,
              you must be aged 18+, currently be employed by an agreed
              third-party supplier to Mitchells & Butlers and hold an email
              address at their agreed domain name. You cannot register for a
              discount code with a personal email address.
            </li>

            <li>
              Discount codes cannot be used to obtain bar drinks ordered when
              food is not purchased (food constitutes a main meal or
              equivalent*) unless stated otherwise in the voucher terms and
              conditions.
            </li>

            <li>
              The proportion of alcohol purchased to food must be sensible.
            </li>

            <li>
              Please always drink responsibly. Alcohol can only be sold to those
              aged 18+ and in accordance with licensing laws, you may be
              required to provide ID to purchase alcohol. A Manager has the
              discretion to refuse service of alcohol to any guest.
            </li>

            <li>
              Only one Supplier Dine With Us discount voucher can be used per
              party of 6 guests. A party of more than 6 guests can comprise of 2
              suppliers, each with their own discount voucher and up to 5 guests
              (i.e. 12 people in total).
            </li>

            <li>
              Fraudulent use or abuse of Supplier Dine With Us discount vouchers
              may result in withdrawal from the scheme.
            </li>

            <li>
              Your personal Supplier Dine With Us voucher is your
              responsibility, it is personalised and for your sole use. Do not
              share your voucher.
            </li>

            <li>
              You must present your Supplier Dine With Us voucher, including
              your photo, on your mobile device when you pay for your meal.
            </li>

            <li>
              You can use up to one Supplier Dine With Us voucher a day. The
              voucher will expire when you redeem it when dining in one of our
              businesses, and you will receive a new voucher to be used the
              following day (subject to T&Cs). A Supplier Dine With Us voucher
              will remain valid until you redeem it unless you have been removed
              from the scheme, deleted your account or your account has been
              suspended.
            </li>

            <li>
              If dining in a business where there is no tab functionality, you
              must order everything in one go to be able to receive the discount
              on your entire bill.
            </li>

            <li>
              The discount applies to all core menu offers including bar menus,
              breakfast and standard fixed price 2 or 3 course menus if a main
              meal, or equivalent* per person is ordered. Small plates and
              buffets are not considered a "main meal" and thus dine with us
              discount cannot be used on these items.
            </li>

            <li>
              Supplier Dine With Us discount is valid as per the terms and
              conditions of each individual voucher, excluding Christmas Day.
            </li>

            <li>
              The discount applies to "order at table" and "click & collect/collection", but not delivery.
            </li>

            <li>
              Supplier Dine with us is not applicable to short term
              promotional/key date set menus in Miller & Carter and Ego (e.g. January
              Set Menu, Valentines Set Menu, Festive Set Menus).
            </li>

            <li>
              Supplier Dine With Us discount cannot be used in conjunction with
              any other special offers, coupons, vouchers, or any other discount
              schemes.
            </li>

            <li>
              Supplier Dine With Us cannot be used in conjunction with any of
              our brand apps, this includes stamp rewards or voucher/offer
              redemption.
            </li>

            <li>
              Supplier Dine With Us discount vouchers cannot be used at any
              other Franchise site or at sites in Germany.
            </li>

            <li>
              Supplier Dine With Us discount vouchers are discretionary and may
              be amended or withdrawn at any time.
            </li>

            <li>
              Mitchells & Butlers reserve the right to withdraw the Supplier
              Dine With Us Scheme from any individual where they are not
              permitted access to our businesses (i.e. are barred).
            </li>

            <li>
              Suppliers' employees only remain eligible for Dine With Us
              Discount while they remain a nominated person by an eligible
              supplier of Mitchells & Butlers. Should they leave their role, no
              further vouchers will be issued and they're current account will
              be terminated.
            </li>

            <li>
              If there are any widespread technical issues with the app, a
              replacement voucher may be sent via email to the email address
              used to register on the app to use in the interim while we resolve
              any glitches.
            </li>

            <li>
              For information on the capture & usage of your data, please{" "}
              <a
                href="https://www.mbplc.com/privacy/"
                target="_blank"
                rel="noreferrer"
              >
                click here
              </a>
              .
            </li>
          </ul>

          <p>*An example of equivalent would be a full breakfast.</p>
        </div>
      </div>
      <div
        className={`absolute block bg-theme-pink w-[648px] aspect-square rounded-full top-[484px] right-[-373px] z-0`}
      />
      <div
        className={`absolute block bg-theme-blue-light w-[343px] aspect-square rounded-full top-[945px] left-[-172px] z-0`}
      />
      <div
        className={`absolute block bg-theme-blue-light w-[218px] aspect-square rounded-full bottom-[292px] right-[-110px] z-0`}
      />
      <div
        className={`absolute block bg-theme-pink w-[479px] aspect-square rounded-full bottom-[-127px] left-[-136px] z-0`}
      />
    </Layout>
  )
}

export default Terms
